import { defineMessages } from 'react-intl';

const NAME = '@@utils.OrderTypeUtils';

export default defineMessages({
  refund: {
    id: `${NAME}.refund`,
    defaultMessage: 'Refund',
  },
  new: {
    id: `${NAME}.new`,
    defaultMessage: 'New',
  },
  renewal: {
    id: `${NAME}.renewal`,
    defaultMessage: 'Renewal',
  },
});
