import _ from 'lodash';
import ease from 'ease-component';
import format from 'date-fns/format';
import scroll from 'scroll';
import Cookies from 'js-cookie';
import moment from 'moment';
import * as Constants from '@internal/constants';
import qs from 'qs';

export function lowKey(obj) {
  return _.mapKeys(obj, (val, key) => key.toLowerCase());
}

export const getLocalDate = utcDate => {
  if (!utcDate) {
    return '';
  }
  // grab the time-less date info
  let localDate = new Date(utcDate).toISOString().split('T')[0];
  // rebuild date with current timezone
  localDate = moment(localDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  // make a new date object
  return new Date(localDate);
};

export function lowKeyNested(obj) {
  const lowNested = lowKey(obj);

  if (lowNested.hasOwnProperty('opportunity')) {
    lowNested.opportunity = lowKey(lowNested.opportunity);
    if (lowNested.opportunity.primary_contact__r) {
      lowNested.opportunity.primary_contact__r = lowKey(
        lowNested.opportunity.primary_contact__r
      );
    }

    if (lowNested.opportunity.agent_id__r) {
      lowNested.opportunity.agent_id__r = lowKey(
        lowNested.opportunity.agent_id__r
      );
    }

    if (lowNested.opportunity.agency_id__r) {
      lowNested.opportunity.agency_id__r = lowKey(
        lowNested.opportunity.agency_id__r
      );
    }

    if (lowNested.opportunity.account) {
      lowNested.opportunity.account = lowKey(lowNested.opportunity.account);
      if (lowNested.opportunity.account.techid__r) {
        lowNested.opportunity.account.techid__r = lowKey(
          lowNested.opportunity.account.techid__r
        );
      }
    }

    if (
      lowNested.opportunity.recordtype &&
      lowNested.opportunity.recordtype.Name ===
        'Climate Portal Purchase Adjustment'
    ) {
      lowNested.opportunity.orderType = Constants.orderType.REFUND;
    } else if (lowNested.opportunity.renewal_date__c) {
      lowNested.opportunity.orderType = Constants.orderType.RENEWAL;
    } else {
      lowNested.opportunity.orderType = Constants.orderType.NEW;
    }
  }
  if (lowNested.hasOwnProperty('user')) {
    lowNested.user = lowKey(lowNested.user);

    lowNested.user.contact = lowKey(lowNested.user.contact);
    lowNested.user.contact.account = lowKey(lowNested.user.contact.account);
  }

  if (lowNested.hasOwnProperty('contact')) {
    lowNested.contact = lowKey(lowNested.contact);
  }
  if (lowNested.hasOwnProperty('otcopportunity')) {
    lowNested.otcopportunity = lowKey(lowNested.otcopportunity);
  }
  if (lowNested.hasOwnProperty('opportunitylineitems')) {
    // must map the all the elements of a property that is an array type.
    lowNested.opportunitylineitems = _.map(
      lowNested.opportunitylineitems,
      oli => {
        const lowOli = lowKey(oli);
        lowOli.package__r = lowKey(lowOli.package__r);
        return lowOli;
      }
    );
  }
  return lowNested;
}

export function sfdcDate(anyDate) {
  const d = anyDate || new Date();
  return format(d, 'YYYY-MM-DD');
}

export function resetScroll() {
  const windowTop = 0;
  scroll.top(document.body, windowTop, { duration: 400, ease: ease.outQuart });
}

export function readCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

export function createCookie(name, value, days) {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  } else {
    expires = '';
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
}

export function replaceSpaces(string, replaceWith = '') {
  let retString = '';
  if (typeof string === 'string') {
    retString = string.replace(/\s+/g, replaceWith).toLowerCase();
  } else if (!_.isEmpty(string)) {
    try {
      //this is due to sometimes a React node could be passed in here, so this is to deal with that
      //and to break without breaking everything. (See the 'name' props of OrderTotalLineItem.js)
      retString = string[0].props.children
        .replace(/\s+/g, replaceWith)
        .toLowerCase();
    } catch (e) {}
  }
  return retString;
}

export function loadClimateCookies(userId) {
  if (process.env.REACT_APP_BUILD_ENV === 'staging' && userId) {
    Cookies.remove('preso');
    Cookies.set('preso', JSON.stringify({ clientId: userId }));
  }
}

export function redirectOtcEntry(operationId, openOrderId) {
  if (openOrderId && operationId) {
    window.location = `/otc2017#/${operationId}/checkout?orderId=${openOrderId}`;
  } else if (operationId) {
    window.location = `/otc2017#/${operationId}/product-selection`;
  } else {
    window.location = '/otc2017#/nooperation/order-history';
  }
}

export function redirectPortalEntry() {
  window.location = '/portal';
}
export function redirectFieldSelection() {
  window.location = 'https://climate.com/static/field-selection-web/';
}
export function redirectKTQueryStrings(data = '', host) {
  //qs is not parsing ? from the query string
  const query = qs.parse(data.replace('?', ''));
  const country = query.country || 'US';
  let lang = query.lang ? query.lang.split('-')[0] : 'en';
  lang = lang.toLowerCase();
  if (query.search) {
    window.location.replace(`${host}#/s/${query.search}/${lang}_${country}`);
  } else if (query.category) {
    window.location.replace(`${host}#/${query.category}/${lang}_${country}`);
  } else {
    window.location.replace(`${host}#/${lang}_${country}`);
  }
}

export function getNested(theObject, path, separator) {
  try {
    separator = separator || '.';

    return path
      .replace('[', separator)
      .replace(']', '')
      .split(separator)
      .reduce((obj, property) => obj[property], theObject);
  } catch (err) {
    return undefined;
  }
}
export function getHashQuery() {
  if (window.location.hash.includes('?')) {
    return qs.parse(
      window.location.hash.substr(window.location.hash.indexOf('?'))
    );
  }
  return {};
}
export const getOrderLink = (order, userId, userCountry) => {
  const operationId = order.opportunity.account.operationid__c;
  let route = `/${operationId}/`;

  switch (order.opportunity.stagename) {
    case Constants.opptyStages.SAVED:
      // Open Grower order
      route += `checkout/?orderId=${order.opportunity.id}`;
      break;

    case Constants.opptyStages.PROCESSING_PAYMENT:
      if (
        userCountry === 'AR' &&
        order.opportunity.payment_method__c === 'monpay'
      ) {
        route += `checkout-monpay?orderId=${order.opportunity.id}`;
      } else {
        route += `order/${order.opportunity.id}`;
      }
      break;

    case Constants.opptyStages.PURCHASED:
      if (order.opportunity.agent_id__r.climate_userid__c === userId) {
        // Open Dealer initiated order
        route += `order/${order.opportunity.id}`;
      } else {
        // Open Dealer initiated order
        route += `?orderId=${order.opportunity.id}`;
        // TODO: send to Grower Confirmation
      }
      break;

    default:
      // Completed order
      route += `order/${order.opportunity.id}`;
  }
  if (
    !window.location.pathname ||
    !window.location.pathname.includes('otc2017')
  ) {
    window.location = `${window.location.origin}/otc2017#${route}`;
  }
  return route;
};

export const isAlphaNumeric = input => {
  const alphaNumericRegex = /^[0-9a-zA-Z]+$/;
  return alphaNumericRegex.test(input);
};

export const isNumeric = input => {
  const alphaNumericRegex = /^[0-9]+$/;
  return alphaNumericRegex.test(input);
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export function GenerateNotaFiscalLink(id, type) {
  return `https://${window.location.hostname}/apex/NotaFiscal?id=${id}&type=${type}`;
}
export function GenerateInvoiceLink(id, language) {
  return `https://${window.location.hostname}/apex/invoice?id=${id}&lang=${language}`;
}
export function GenerateMonpaytLink(id) {
  return `https://${window.location.hostname}/apex/Monpay?id=${id}&origin=https://${window.location.hostname}`;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}
