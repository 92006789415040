/*
 * Inject the static resource path at runtime, e.g. `{!$Resource.MyApp}/`
 * (!) The file `visualforce.html` must contain this js snippet:
 *     `window.webpackPublicPath = '%PUBLIC_URL%/';`
 * (!) This must be the first import in the app's entry
 *
 * See: https://webpack.js.org/guides/public-path/
 */
if (window.webpackPublicPath) {
  // eslint-disable-next-line no-undef
  __webpack_public_path__ = window.webpackPublicPath;
}
