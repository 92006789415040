import React, { Component } from 'react';
import Loading from 'components/Loading';
import { redirect } from 'utils/utils';

class App extends Component {
  async componentDidMount() {
    await redirect();
  }

  render() {
    return (
      <div className="App">
        <Loading showLoading={true} />
      </div>
    );
  }
}

export default App;
