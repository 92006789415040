export const externalUrls = {
  apiBaseUrl: 'https://api.climate.com',
  staticBaseUrl: 'https://climate.com/static',
  linkBaseUrl: 'https://climate.com',
};

export const pathNames = {
  inventory: 'product-selection/inventory',
  wholeFarm: 'product-selection/whole-farm',
};

export const noInventoryOrdersAllowed = {
  cps: 'CROP PRODUCTION SERV',
};

export const taxExemptMonsantoDealers = {
  winfieldUnited: 'WINFIELD SOLUTIONS',
};

export const originNames = {
  dealerForGrower: 'Dealer for Grower',
  dealerForInventory: 'Dealer for Inventory',
  dealerForSelf: 'Dealer for Self',
  growerDirect: 'Grower Direct',
  internalOrder: 'Internal Order',
  loyalty: 'Loyalty',
};

export const cpsList = ['NUTRIEN AG SOLUTIONS', 'CROP PRODUCTION SERV'];

export const autoRenewStatus = {
  yesGrower: 'Yes - Grower',
  noGrower: 'No - Grower',
  yesSelfOptIn: 'Yes - Self opt-in',
};

export const opptyStages = {
  CLOSED_WON: 'Closed Won',
  PURCHASED: 'Purchased',
  PENDING_RENEWAL: 'Pending Renewal',
  PROCESSING_PAYMENT: 'Processing Payment',
  SAVED: 'Saved',
  CLOSED_LOST: 'Closed Lost',
  CANCELLED: 'Cancelled',
  AWAITING_REVIEW: 'Awaiting Review',
};

export const orderType = {
  REFUND: 'Refund',
  RENEWAL: 'Renewal',
  NEW: 'New',
};

export const PAYMENT_BANK_TRANSFER = 'banktransfer';
export const PAYMENT_BOLETO = 'boleto';
export const PAYMENT_CHECKING = 'checking';
export const PAYMENT_CREDIT = 'credit';
export const PAYMENT_DEBIT = 'debit';
export const PAYMENT_DIRECT_DEBIT = 'directDebit';
export const PAYMENT_EXTERNAL = 'external';
export const PAYMENT_GROWER_DEFERRED = 'grower-deferred';
export const PAYMENT_INVOICE = 'invoice';
export const PAYMENT_SAVING = 'saving';
export const PAYMENT_SRP = 'srp';
export const PAYMENT_MONPAY = 'monpay';

export const SPA_ENABLED = 'SPA Enabled';
export const SPA_FIELD_OTC = 'SPA FIELD OTC';
export const PRODUCT_KEY_CLIMATE_PRO = 'climate_pro';
export const PRODUCT_KEY_IMPULSO = 'fieldview_impulso';
export const PRODUCT_KEY_PLUS_ACRE = 'fieldview_plus_acre';
export const PRODUCT_KEY_PLUS = 'fieldview_connect';
export const PRODUCT_KEY_PLUS_Y2 = 'fieldview_connect_2y';
export const PRODUCT_KEY_PLUS_Y3 = 'fieldview_connect_3y';
export const PRODUCT_KEY_PLUS_Y4 = 'fieldview_connect_4y';
export const PRODUCT_KEY_PLUS_Y5 = 'fieldview_connect_5y';
export const PRODUCT_KEY_PLUS_Y6 = 'fieldview_connect_6y';
export const PRODUCT_KEY_PLUS_Y7 = 'fieldview_connect_7y';
export const PRODUCT_KEY_PREMIUM = 'fieldview_premium';
export const PRODUCT_KEY_HECTARES = 'fieldview_plus_hectare';
export const PRODUCT_KEY_PRIME = 'FVPRIME';
export const PRODUCT_KEY_PRIME_IMPULSO = 'fvprime_impulso';
export const PRODUCT_KEY_GROWER_SCRIPTING = 'grower_scripting';
export const PRODUCT_KEY_EUFVPRIME = 'fieldview_plus_prime';
export const PRODUCT_KEY_PRIME_CA = 'fieldview_prime_ca';
export const PRODUCT_KEY_PRIME70 = 'FVPRIME_70';
export const PRODUCT_KEY_PRIME200 = 'FVPRIME_200';
export const PRODUCT_KEY_PRIME500 = 'FVPRIME_500';
export const ASSET_KEY_PRIME = 'NonConnected';
export const ASSET_KEY_PREMIUM = 'Premium';
export const ASSET_KEY_GROWERBULKRX = 'GrowerBulkRx';
export const ASSET_KEY_DEALERBULKRX = 'DealerBulkRx';
export const ASSET_KEY_PAIDPRIME = 'PaidPrime';
export const ASSET_KEY_PLUS = 'FieldViewPlus';
export const ASSET_KEY_PRO = 'ClimatePro';
export const ASSET_KEY_SCRIPTING = 'AdvancedScripting';
export const PRODUCT_KEY_SPA_FULL = 'spa_full';
export const PRODUCT_KEY_NITROGEN_FULL = 'nitrogen_full';
export const PRODUCT_KEY_SCRIPTING_FULL = 'scripting_full';
export const PRODUCT_KEY_SCRIPTING_OPERATION = 'scripting_operation';
export const PACKAGE_KEY_KIT_FVD_MK_PROMO = 'KIT_FVD_MK_PROMO';
export const PACKAGE_KEY_AR_CABIN_PLUS = 'ARCabinPackPlusPackage';
export const PRODUCT_KEY_EU_IPAD_PROMO = 'FREE_IPAD_PROMO';
export const PRODUCT_KEY_HW_DRIVE = 'fieldview_drive';
export const PRODUCT_KEY_HW_IPAD_MK = 'HW_IPAD_MK';
export const PRODUCT_KEY_IPAD_128_CELLULAR = 'HW_Ipad_128_Cellular';
export const PRODUCT_KET_KIT_FVD = 'KIT_FVD_MK';
export const PRODUCT_KEY_FVD_INTERNAL_TOOL = 'FieldView Drive';
export const PRODUCT_KEY_FVD_KIT_INTERNAL_TOOL = 'FieldView Drive Package';
export const OTC_PRODUCT_SELECTION = 'OTC_ProductSelection';
export const OTC_Checkout = 'OTC_Checkout';

export const STRIPE_SETUP_SUCCEEDED = 'succeeded';
export const STRIPE_SETUP_REQUIRES_ACTION = 'requires_action';
export const STRIPE_SETUP_REQUIRES_PAYMENT_METHOD = 'requires_payment_method';
export const STRIPE_SETUP_FAILED = 'failed';
export const STRIPE_SETUP_REQUIRES_CONFIRMATION = 'requires_confirmation';
export const STRIPE_SETUP_NOT_VERIFIED = 'not_verified';
export const STRIPE_MANDATE_TYPE = 'online';
export const STRIPE_PAYMENT_METHOD_US_BANK_ACCOUNT = 'us_bank_account';

export const acreProducts = [PRODUCT_KEY_PLUS_ACRE];
export const PRODUCT_KEYS_AREA = [PRODUCT_KEY_HECTARES, PRODUCT_KEY_PLUS_ACRE];

export const PRODUCT_KEYS_PRO_SOLUTIONS = [
  PRODUCT_KEY_SPA_FULL,
  PRODUCT_KEY_NITROGEN_FULL,
  PRODUCT_KEY_SCRIPTING_FULL,
];

export const fieldView = 'FieldView™';
export const climateFieldView = 'Climate FieldView™';
export const climateFieldViewPrime = `${climateFieldView} Prime`;
export const climateFieldViewPro = `${fieldView} Pro`;
export const climateFieldViewPlus = `${fieldView} Plus`;

export const appLinks = {
  prime: {
    ios: 'https://itunes.apple.com/us/app/id797902820?mt=8',
    android:
      'https://play.google.com/store/apps/details?id=com.climate.growers.android.release&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  },
  plus: {
    ios: 'https://itunes.apple.com/us/app/fieldview/id489885110?mt=8',
  },
  pro: {
    ios: 'https://itunes.apple.com/us/app/id797902820?mt=8',
    android:
      'https://play.google.com/store/apps/details?id=com.climate.growers.android.release&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  },
};

export const googlePlayBadgeUrl =
  'https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png';

export const shippingKeys = ['shipping', 'shipping_two', 'shipping_exp'];

export const multiYearKeysToYear = {
  [PRODUCT_KEY_PLUS_Y2]: 2,
  [PRODUCT_KEY_PLUS_Y3]: 3,
  [PRODUCT_KEY_PLUS_Y4]: 4,
  [PRODUCT_KEY_PLUS_Y5]: 5,
  [PRODUCT_KEY_PLUS_Y6]: 6,
  [PRODUCT_KEY_PLUS_Y7]: 7,
};

// Whole Farm (Keep these in ascending order)
export const wholeFarmKeys = [
  'whole_farm_tier1',
  'whole_farm_tier2',
  'whole_farm_tier3',
];

export const fieldViewPlusKey = 'fieldview_connect';

export const fieldviewPlusMultiYearVariants = [
  PRODUCT_KEY_PLUS_Y2,
  PRODUCT_KEY_PLUS_Y3,
  PRODUCT_KEY_PLUS_Y4,
  PRODUCT_KEY_PLUS_Y5,
  PRODUCT_KEY_PLUS_Y6,
  PRODUCT_KEY_PLUS_Y7,
];

export const fieldViewPlusKeys = {
  fieldviewConnect: PRODUCT_KEY_PLUS,
  fieldviewConnectY2: PRODUCT_KEY_PLUS_Y2,
  fieldviewConnectY3: PRODUCT_KEY_PLUS_Y3,
  fieldviewConnectY4: PRODUCT_KEY_PLUS_Y4,
  fieldviewConnectY5: PRODUCT_KEY_PLUS_Y5,
  fieldviewConnectY6: PRODUCT_KEY_PLUS_Y6,
  fieldviewConnectY7: PRODUCT_KEY_PLUS_Y7,
  fieldviewHectare: PRODUCT_KEY_HECTARES,
  fieldviewAcre: PRODUCT_KEY_PLUS_ACRE,
  fieldviewImpulso: PRODUCT_KEY_IMPULSO,
};

export const fieldViewPrimeKeys = [
  PRODUCT_KEY_PRIME,
  PRODUCT_KEY_EUFVPRIME,
  PRODUCT_KEY_PRIME_IMPULSO,
  PRODUCT_KEY_PRIME_CA,
];

export const fieldViewPremiumKeys = [PRODUCT_KEY_PREMIUM];

export const assetKeyPrime = [ASSET_KEY_PRIME, ASSET_KEY_PAIDPRIME];
export const assetKeyPremium = [ASSET_KEY_PREMIUM];
export const assetKeyPlus = [ASSET_KEY_PLUS];
export const assetKeyPro = [ASSET_KEY_PRO];
export const assetKeyScripting = [ASSET_KEY_SCRIPTING, ASSET_KEY_GROWERBULKRX];
export const assetKeyDealerScripting = [ASSET_KEY_DEALERBULKRX];

export const fieldViewPrimeCoupons = [
  PRODUCT_KEY_PRIME70,
  PRODUCT_KEY_PRIME200,
  PRODUCT_KEY_PRIME500,
];

export const dekalbGroup = 'EU Dekalb';
export const dekalbGroupOSR = 'EU DekalbOSR';
export const dekalbGroupAssociations = [dekalbGroup, dekalbGroupOSR];

export const CHECKOUT_SECT_ACCOUNT = 'account';
export const CHECKOUT_SECT_SHIPPING = 'shipping';
export const CHECKOUT_SECT_PAYMENT = 'payment';
export const CHECKOUT_SECT_TAX = 'tax';
export const CHECKOUT_SECT_TAX_EU = 'taxEU';
export const CHECKOUT_SECT_PROMOTION = 'promotion';

export const DriveCompatibilityLink = {
  US: 'https://sites.google.com/view/cfvcompatibility/home',
  CA: 'http://www.setupclimatefieldview.ca/',
  BR: 'https://climate.com/static/conecta/',
};

export const usRefundReasonOptions = {
  satisfactionProgram: 'Satisfaction Guarantee Program',
  notUseAsIntended: 'Could Not Use Product as Intended',
  damaged: 'Damaged',
  missedPromoCode: 'Missed Promo Code',
  duplicateOrder: 'Duplicate Order',
  technicalReason: 'Technical Reason',
  orderingDeliveryIssue: 'Ordering or Delivery Issue',
  orderCancellation: 'Order Cancellation',
  notCompatible: 'Not compatible',
  wrongProduct: 'Wrong product',
  excessProduct: 'Excess Product',
  freeTrialMarketing: 'Free Trial/Marketing Program',
  noLongerInterested: 'No longer interested in product (auto-renewal)',
};

export const bulkMessageHectareLimit = {
  AR: 10000,
};

export const PRIME_AVAILABLE_COUNTRIES = [
  'AR',
  'BE',
  'BG',
  'BR',
  'CZ',
  'DE',
  'ES',
  'FR',
  'GB',
  'GR',
  'HU',
  'IT',
  'PL',
  'PT',
  'RO',
  'TR',
  'UA',
];

export const allCountriesISOCodes = [
  'AR',
  'BE',
  'BG',
  'BR',
  'CA',
  'CZ',
  'DE',
  'ES',
  'FR',
  'GB',
  'GR',
  'HU',
  'IT',
  'PL',
  'PT',
  'PY',
  'RO',
  'TR',
  'UA',
  'US',
  'UY',
  'ZA',
];

export const refundReasonsByRefundType = {
  'Full Adjustment': [
    'Satisfaction Guarantee Program',
    'Could Not Use Product as Intended',
    'Damaged',
    'Missed Promo Code',
    'Duplicate Order',
    'Technical Reason',
    'Ordering or Delivery Issue',
    'Order Cancellation',
    'Not compatible',
    'Wrong product',
    'Excess Product',
    'Free Trial/Marketing Program',
    'No longer interested in product (auto-renewal)',
  ],
  'Partial Adjustment': [
    'Damaged',
    'Missed Promo Code',
    'Duplicate Order',
    'Technical Reason',
    'Ordering or Delivery Issue',
    'Order Cancellation',
    'Not compatible',
    'Wrong product',
    'Excess Product',
    'Free Trial/Marketing Program',
    'No longer interested in product (auto-renewal)',
  ],
  'PPI Adjustment': [
    'Portal/Platform Issues',
    'Product did not perform as sold/intended',
    'Not trained on product adequately',
    'Did not believe in product results',
    'Other',
  ],
  'Customer Accommodation': [
    'Other',
    'Request after 30 days/Request outside of return policy',
    'Marketing Program Exception',
    'Special Offer outside of published marketing program',
  ],
};

export const documentType = {
  notaFiscalSoftware: 'notaFiscalSoftware',
  notaFiscalHardware: 'notaFiscalHardware',
  boleto: 'boleto',
  invoice: 'invoice',
  downloadDocuments: 'downloadDocuments',
  orderConfirmation: 'orderConfirmation',
};

export const DRIVE_INSTALL_OPTIONS_TEXT = {
  support: 'On farm support from FieldView requested',
  dealer: 'Dealer will install',
  farmer: 'Farmer will self install',
};

export const noPromoPromotionCode = 'NOPROMO';

export const FREE_SUBSCRIPTION_PAYMENT_TYPES_BY_COUNTRY = {
  ZA: [PAYMENT_CREDIT, PAYMENT_DIRECT_DEBIT, PAYMENT_BANK_TRANSFER],
  AR: [PAYMENT_MONPAY],
  ES: [PAYMENT_CREDIT, PAYMENT_DIRECT_DEBIT],
  IT: [PAYMENT_CREDIT, PAYMENT_DIRECT_DEBIT, PAYMENT_BANK_TRANSFER],
  DE: [PAYMENT_CREDIT, PAYMENT_DIRECT_DEBIT, PAYMENT_BANK_TRANSFER],
  GB: [PAYMENT_CREDIT, PAYMENT_DIRECT_DEBIT],
  PL: [PAYMENT_CREDIT, PAYMENT_DIRECT_DEBIT, PAYMENT_BANK_TRANSFER],
  FR: [PAYMENT_CREDIT, PAYMENT_DIRECT_DEBIT],
};

export const DRIVE_INSTALL_OPTIONS_BY_COUNTRY = {
  US: ['support', 'dealer', 'farmer'],
  CA: ['support', 'farmer'],
};

// auto-renewing products that have their payment information collected when first year is free
export const AUTO_RENEWAL_SUBSCRIPTIONS = [
  PRODUCT_KEY_HECTARES,
  PRODUCT_KEY_EUFVPRIME,
  PRODUCT_KEY_PLUS,
  PRODUCT_KEY_PRIME,
];

export const ALLOW_SHOW_AUTO_RENEWAL_BY_COUNTRY = {
  AR: false,
  BE: true,
  BG: true,
  BR: true,
  CA: true,
  CZ: true,
  DE: true,
  ES: true,
  FR: true,
  GB: true,
  GR: true,
  HU: true,
  IT: true,
  PL: true,
  PT: true,
  PY: true,
  RO: true,
  TR: true,
  UA: true,
  US: true,
  UY: true,
  ZA: true,
};

export const REQUIRED_PROMOTION_PRODUCTS = {
  UA: [PRODUCT_KEY_PLUS, PRODUCT_KEY_EUFVPRIME],
};

export const COUNTRIES_FOR_PLUS_RENEWAL = ['BR', 'AR'];

export const COUNTRIES_FOR_PRIME_RENEWAL = ['BR', 'AR'];

export const NO_DECIMAL_CURRENCIES = ['HUF'];

export const UNIQUE_BAYER_CODE_COUNTRIES = ['CA'];

export const TECH_ID_COUNTRIES = ['US', 'CA'];

export const WHOLE_SCRIPT_BUTTON_GROUP_ASSOCIATION = 'Whole Farm Rx';

export const B2B_DEALER_GROUP_ASSOCIATION = 'B2B Dealer';

export const COUNTRIES_FOR_CONTRACT_DETAILS = ['UA'];
