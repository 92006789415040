const countryLangs = {
  US: [],
  CA: ['fr', 'en'],
  BR: ['en', 'pt'],
  //EU english only 2/2/2018
  UA: [],
  FR: [],
  DE: [],
  IT: [],
  RO: [],
  ES: [],
  AU: [],
  ZA: [],
};

export const defaultLocales = {
  US: 'en_US',
  CA: 'en_CA',
  BR: 'pt_BR',
  ZA: 'af_ZA',
  UA: 'en_UA',
  FR: 'en_FR',
  DE: 'en_DE',
  IT: 'en_IT',
  RO: 'en_RO',
  ES: 'en_ES',
  AU: 'en_AU',
};

export const getLanguages = (country = 'US') => countryLangs[country];

export const getDefaultLocale = country => {
  if (country && typeof country === 'string') {
    return defaultLocales[country] || defaultLocales.US;
  }
  const hostLocales = {
    'support.climatefieldview.ca': defaultLocales.CA,
    'support.climatefieldview.com.br': defaultLocales.BR,
    'support.climatefieldview.com.ua': defaultLocales.UA,
    'support.climatefieldview.fr': defaultLocales.FR,
    'support.climatefieldview.de': defaultLocales.DE,
    'support.climatefieldview.it': defaultLocales.IT,
    'support.climatefieldview.es': defaultLocales.ES,
    'support.climatefieldview.ro': defaultLocales.RO,
  };
  return hostLocales[window.location.host] || 'en_US';
};

export const getSupportDomain = () => {
  if (window.marketingNavApp === 'EventApp') {
    return {
      US: 'https://events.climate.com?country=US',
      CA: 'https://events.climate.com?country=CA',
      BR: 'https://events.climate.com?country=BR',
      UA: 'https://events.climate.com?country=UA',
      FR: 'https://events.climate.com?country=FR',
      DE: 'https://events.climate.com?country=DE',
    };
  }
  const supportHostnames = [
    'support.climate.com',
    'support.climatefieldview.ca',
    'support.climatefieldview.com.br',
    'support.climatefieldview.com.ua',
    'support.climatefieldview.fr',
    'support.climatefieldview.de',
    'support.climatefieldview.it',
    'support.climatefieldview.es',
    'support.climatefieldview.ro',
  ];
  if (supportHostnames.includes(window.location.host)) {
    return {
      US: 'https://support.climate.com/kt#',
      CA: 'https://support.climatefieldview.ca/kt#',
      BR: 'https://support.climatefieldview.com.br/kt#',
      UA: 'https://support.climatefieldview.com.ua/kt#',
      FR: 'https://support.climatefieldview.fr/kt#',
      DE: 'https://support.climatefieldview.de/kt#',
      IT: `https://support.climate.com/kt#/${defaultLocales.IT}`,
      RO: `https://support.climate.com/kt#/${defaultLocales.Ro}`,
      ES: `https://support.climate.com/kt#/${defaultLocales.ES}`,
    };
  }
  return {
    US: `//${window.location.host}/kt#`,
    CA: `//${window.location.host}/kt#`,
    BR: `//${window.location.host}/kt#`,
    UA: `//${window.location.host}/kt#`,
    FR: `//${window.location.host}/kt#`,
    DE: `//${window.location.host}/kt#`,
    IT: `//${window.location.host}/kt#`,
    RO: `//${window.location.host}/kt#`,
    ES: `//${window.location.host}/kt#`,
  };
};
