import _ from 'lodash';
import { countryData, useTerminalStreetNumber, isEurope } from './CountryUtils';

const google = window.google;
let AutocompleteService;

export function statesList(country = 'US') {
  return countryData[country.toUpperCase()].states;
}

export function postalCodeLabel(country = 'US') {
  return countryData[country.toUpperCase()].postalCodeLabel;
}

export function postalCodeLength(countryCode = 'US') {
  return countryData[countryCode.toUpperCase()].postalCodeLength;
}

export function stateLabel(countryCode = 'US') {
  return countryData[countryCode.toUpperCase()].stateLabel;
}

export function usesTerminalStreetNumber(countryCode = 'US') {
  return useTerminalStreetNumber.includes(countryCode.toUpperCase());
}

export const requiredFields = {
  billing: {
    firstname: countryCode => false,
    lastname: countryCode => false,
    street: countryCode => true,
    street2: countryCode => false,
    city: countryCode => true,
    state: countryCode => !isEurope(countryCode),
    postalcode: countryCode => true,
    streetnumber: countryCode => false,
    neighborhood: countryCode => countryCode === 'BR',
    company: countryCode =>
      isEurope(countryCode) && countryCode !== 'PT' && countryCode !== 'GR',
    county: countryCode => ['ES', 'DE', 'FR'].indexOf(countryCode) > -1,
  },
  shipping: {
    firstname: countryCode => true,
    lastname: countryCode => true,
    street: countryCode => true,
    street2: countryCode => false,
    city: countryCode => true,
    state: countryCode => !isEurope(countryCode),
    postalcode: countryCode => true,
    streetnumber: countryCode => false,
    neighborhood: countryCode => countryCode === 'BR',
    county: countryCode => countryCode === 'RO',
    company: countryCode => false,
  },
};

export const requiredFieldsForDisplay = (addressType = 'shipping') =>
  Object.keys(requiredFields[addressType]).sort((a, b) => {
    const displayOrderMap = requiredFieldsDisplayOrderMap;
    if (displayOrderMap[a] > displayOrderMap[b]) {
      return 1;
    } else if (displayOrderMap[a] < displayOrderMap[b]) {
      return -1;
    }
    return 0;
  });

export const requiredFieldsDisplayOrderMap = {
  firstname: 1,
  lastname: 2,
  streetnumber: 3,
  street: 4,
  street2: 5,
  neighborhood: 6,
  county: 7,
  city: 8,
  state: 9,
  postalcode: 10,
};

export function fieldIsRequired(addressType, fieldKey, countryCode) {
  if (!requiredFields[addressType] || !requiredFields[addressType][fieldKey]) {
    return false;
  }
  return requiredFields[addressType][fieldKey](countryCode);
}

export function missingRequiredField(address, editingKey, country = 'US') {
  let missingField = '';
  _.some(requiredFields[editingKey], (isRequired, key) => {
    const fieldValue = address[key] || '';
    if (isRequired(country) && _.isEmpty(fieldValue.trim())) {
      missingField = key;
      return true;
    }
    return false;
  });
  return missingField;
}

export function validateRequiredFields(address, editingKey, country = 'US') {
  const missingField = missingRequiredField(address, editingKey, country);
  return !missingField;
}

export function currencyLabel(country = 'US') {
  return countryData[country.toUpperCase() || 'US'].currencyLabel;
}

export function flattenAutocompleteAddress(autocompletePlace) {
  const _placeKeys = {
    street_number: 'short_name',
    route: 'short_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    postal_code: 'long_name',
  };
  const autocompleteKeys = _.keys(_placeKeys);

  const flattenedPlace = {};
  _.forEach(autocompletePlace.address_components, componentObject => {
    if (_.includes(autocompleteKeys, componentObject.types[0])) {
      const attributeToUse = _placeKeys[componentObject.types[0]];
      flattenedPlace[componentObject.types[0]] =
        componentObject[attributeToUse];
    }
  });
  return flattenedPlace;
}

// TODO: Make this validation work for non-English input
export function isPoBox(address = '') {
  const poBox = /^ *((#\d+)|((box|bin)[-. /\\]?\d+)|(.*p[ .]? ?(o|0)[-. /\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i;
  return poBox.test(address);
}

export function isRuralRoute(address = '') {
  const rrRegex = /^rr|rural route/gim;
  return rrRegex.test(address.trim());
}

const shippingValidations = {
  street: (value, countryCode) =>
    countryCode !== 'US' || (!isPoBox(value) && !isRuralRoute(value)),
  street2: (value, countryCode) =>
    countryCode !== 'US' || (!isPoBox(value) && !isRuralRoute(value)),
};

export function isShippingValid(addr, countryCode) {
  return Object.entries(addr).every(([key, value]) => {
    const validator = shippingValidations[key];
    const noValidator = typeof validator !== 'function';
    return noValidator || validator(value, countryCode);
  });
}

function getAutocompleteDetails(input, place_id, callback) {
  const placeService = new google.maps.places.PlacesService(input);
  placeService.getDetails({ placeId: place_id }, (place, newStatus) => {
    if (newStatus !== google.maps.places.PlacesServiceStatus.OK) {
      console.error('[GPLACES-PLACEDETAIL-ERROR]', newStatus);
      return;
    }
    callback(flattenAutocompleteAddress(place));
  });
}
function removeGoogleList() {
  if (document.getElementsByClassName('googlePlaceList').length > 0) {
    document
      .getElementsByClassName('googlePlaceList')[0]
      .parentNode.removeChild(
        document.getElementsByClassName('googlePlaceList')[0]
      );
  }
}
window.addEventListener('click', removeGoogleList);
export function fetchAutocomplete(input, callback, country = 'US') {
  if (!AutocompleteService) {
    AutocompleteService = new google.maps.places.AutocompleteService();
  }
  const tar = input.target;
  const options = {
    types: ['address'],
    componentRestrictions: { country: country.toLowerCase() },
  };
  if (!isPoBox(input.target.value)) {
    AutocompleteService.getPlacePredictions(
      { input: input.target.value, ...options },
      (predictions, status) => {
        const data = predictions || [];
        removeGoogleList();
        const ul = document.createElement('ul');
        ul.setAttribute('class', 'googlePlaceList');
        tar.parentElement.appendChild(ul);
        for (let i = 0; i < data.length; i++) {
          const li = document.createElement('li');
          li.setAttribute('id', data[i].place_id);
          li.addEventListener(
            'click',
            ev => {
              ev.stopPropagation();
              getAutocompleteDetails(tar, ev.target.id, callback);
              removeGoogleList();
            },
            false
          );
          li.appendChild(document.createTextNode(data[i].description));
          ul.appendChild(li);
        }
      }
    );
  } else {
    removeGoogleList();
  }
}

export function initAutocomplete(
  inputElement,
  fillAddressCallback,
  country = 'US'
) {
  if (google && inputElement) {
    const options = {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    };
    // Both SFDC and Google use two-character ISO-3166
    // alpha-2 country codes, but Google needs lowercase
    if (country) {
      options.componentRestrictions = { country: country.toLowerCase() };
    }
    const autocomplete = new google.maps.places.Autocomplete(
      inputElement,
      options
    );
    autocomplete.addListener('place_changed', e => {
      if (!autocomplete.getPlace().address_components) {
        const autoService = new google.maps.places.AutocompleteService();
        autoService.getPlacePredictions(
          {
            input: inputElement.value,
            ...options,
          },
          (predictions, status) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              console.error('[GPLACES-AUTOCOMPLETE-ERROR]', status);
              return;
            }
            const placeService = new google.maps.places.PlacesService(
              inputElement
            );
            placeService.getDetails(
              { placeId: predictions[0].place_id },
              (place, newStatus) => {
                if (newStatus !== google.maps.places.PlacesServiceStatus.OK) {
                  console.error('[GPLACES-PLACEDETAIL-ERROR]', newStatus);
                  return;
                }
                fillAddressCallback(flattenAutocompleteAddress(place));
              }
            );
          }
        );
        return;
      }
      fillAddressCallback(flattenAutocompleteAddress(autocomplete.getPlace()));
    });
  }
}

export function isBillingPostalCodeValid(billingPostalCode, postalCodeLength) {
  if (
    postalCodeLength >= 0 &&
    (!billingPostalCode || billingPostalCode.length !== postalCodeLength)
  ) {
    return false;
  }
  return true;
}
