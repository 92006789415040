import { getArticleId } from './operations';
import { Utils } from '@internal/utils';
import { getDefaultLocale } from '@internal/kt-app/src/utils/IntlUtils';

//apex pages cause 500 and have to be forced redirects
const forceRedirect = [
  'liveagent_offline',
  'liveagent_postchat',
  'liveagent_prechat',
  'case_submitted',
  'pkb_liveagent_offline',
  'pkb_liveagent_prechat',
  'supportRobotsTxt',
];
const currentHost = `${window.location.origin}/kt`;

export const redirect = async () => {
  if (window.location.search) {
    return Utils.redirectKTQueryStrings(window.location.search, currentHost);
  }
  let loc = window.location.pathname.replace(/\/$|\/\?.*/, '');
  const defaultLocale = `/${getDefaultLocale()}`;
  if (loc.includes('article')) {
    let articleId = loc.split('/');
    articleId = articleId[articleId.length - 1];
    try {
      const resp = await getArticleId(articleId);
      const id = resp.KnowledgeArticleId;
      if (id) {
        window.location.replace(`${currentHost}#/${id}${defaultLocale}`);
      } else {
        window.location.replace(`${currentHost}#${defaultLocale}`);
      }
    } catch (e) {
      window.location.replace(`${currentHost}#${defaultLocale}`);
    }
  } else if (window.location.hash.includes('article')) {
    loc = window.location.hash.replace(/\/$|\/\?.*/, '').split('/');
    //url contains article ID
    if (loc[2]) {
      window.location.replace(`${currentHost}#/${loc[2]}${defaultLocale}`);
    } else {
      window.location.replace(`${currentHost}#${defaultLocale}`);
    }
  } else if (window.location.hash.includes('category/l:en_US/Cab_App')) {
    window.location.replace(`${currentHost}#/Cab_App/en_US`);
  } else if (window.location.hash.includes('category/l:en_AU/Cab_App')) {
    window.location.replace(`${currentHost}#/Cab_App_AU/en_AU`);
  } else if (forceRedirect.includes(loc.split('/')[1])) {
    window.location.replace(`${currentHost}#${defaultLocale}`);
  } else if (!window.location.pathname.includes('kt')) {
    if (window.location.hash) {
      window.location.replace(currentHost + window.location.hash);
    } else {
      window.location.replace(`${currentHost}#${defaultLocale}`);
    }
  }
};
