import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from '@tcc/crate';
import './Loading.css';

const Loading = props => {
  const { showLoading } = props;
  return (
    <div className={showLoading ? 'Loading' : ''}>
      {showLoading && <Spinner size="70px" />}
    </div>
  );
};

Loading.propTypes = {
  showLoading: PropTypes.bool,
};

export default Loading;
