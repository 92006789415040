export const getHasBillingTaxInfo = (billingAddress, taxData) => {
  let taxMissing = true;
  let addrMissing = true;
  Object.values(billingAddress).forEach(val => {
    if (val !== '') {
      addrMissing = false;
    }
  });
  Object.values(taxData).forEach(val => {
    if (val !== '') {
      taxMissing = false;
    }
  });
  return addrMissing === true || taxMissing === true ? false : true;
};
